import { filterGroupToHomeEventType } from "src/data/events/logic/homeEventConstants"
import {
  THomeEventFilterGroup,
  THomeEventType,
} from "src/data/events/types/homeEventTypes"

export function mapFilterGroupsToHomeEventTypes(
  filterGroups: THomeEventFilterGroup[]
): THomeEventType[] {
  const eventTypes = filterGroups
    .map((e) => filterGroupToHomeEventType[e])
    .flat()
  return [...new Set(eventTypes)] // Set operation removes potential duplicates
}
